import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=7d7a7d7b&scoped=true"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=7d7a7d7b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d7a7d7b",
  null
  
)

export default component.exports